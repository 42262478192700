import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
// import theme from '../theme/theme'

import Layout from '../components/pageLayout/Layout'
import SEO from '../components/atoms/Seo'
import PageTitle from '../components/atoms/PageTitle'
import PortfolioImage from '../components/atoms/PortfolioImage'

const PortfolioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${({ theme: { pageMargins } }) => `
    padding: ${pageMargins.sm}px;
  `};
`

const IndexPage = () => {
  const {
    wordpressPage: { acf: pageData },
    allWordpressPost: { nodes: portolioProjects },
  } = useStaticQuery(graphql`
    {
      wordpressPage(wordpress_id: { eq: 159 }) {
        acf {
          page_description
          page_title
        }
      }
      allWordpressPost(
        filter: { acf: { is_on_portfolio: { eq: true } } }
        sort: { fields: acf___project_order }
      ) {
        nodes {
          slug
          wordpress_id
          acf {
            project_title
            project_main_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            project_order
          }
          link
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <PageTitle titleText={pageData.page_title} />
      <PortfolioContainer>
        {portolioProjects.map(({ acf, link, wordpress_id: wpId }) => (
          <PortfolioImage
            fluidImageUrl={
              acf.project_main_image.localFile.childImageSharp.fluid
            }
            link={link}
            title={acf.project_title}
            alt={acf.project_title}
            maxColumns={{ xs: 2, md: 3 }}
            key={wpId}
          />
        ))}
      </PortfolioContainer>
    </Layout>
  )
}

export default IndexPage
